/* .perspective-container {
  position: relative;
  perspective: 1000px;
  height: 100vh;
  gap: 1rem;
}

.main-card {
  width: 30rem !important;
  height: 20rem !important;
  transform: rotateX(-15deg) rotateY(20deg);
  transform-origin: center bottom;
}

.main-card::before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 15px;
  height: 10%;
  width: 70%;
  box-shadow: -25px -4px 4px 0 rgba(0, 0, 0, 0.75);
  transform: skewX(60deg);
}

.secondary-card {
  width: 15rem !important;
  height: 20rem !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
  transform: rotateX(60deg) rotateY(-2deg);
  transform-origin: center top;
} */

.card-container {
  perspective: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.main-card {
  background: linear-gradient(145deg, #333, #111); 
  color: #fff;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.9);
  transform: rotateX(-15deg) rotateY(15deg) rotateZ(-2deg); 
  transform-origin: center bottom; 
}

.secondary-card {
  width: 25rem;
  left: 20px;
  background: linear-gradient(145deg, #222, #000); 
  color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7); 
  transform: rotateX(50deg) rotateY(7deg) rotateZ(-17deg); 
  transform-origin: center center;
}

.beta-form-label::after{
  background-color: #111 !important;
}


/* .time-segment-value {
  box-shadow: 0px 0px 5px 1px rgb(255, 140, 0) !important;
} */

/* #countdown-timer {
  background-color: #333;
  padding: 20px;
  font-size: 1.2em;
} */

#countdown-timer {
  background-color: #333;
  padding: 20px;
  font-size: 1.2em;
  transform-origin: center;
  /* Ensures scaling happens from the center */
}

.flip-clock {
  --fcc-flip-duration: 0.5s; /* transition duration when flip card */
  --fcc-spacing: 8px; /* space between unit times and separators */
  --fcc-digit-block-width: 40px; /* width of digit card */
  --fcc-digit-block-height: 60px; /* height of digit card, highly recommend in even number */
  --fcc-digit-block-radius: 35%; /* border radius of digit card */
  --fcc-digit-block-spacing: 5px; /* space between blocks in each unit of time */
  --fcc-digit-font-size: 30px; /* font size of digit */
  --fcc-digit-color: rgb(255, 140, 0); /* color of digit */
  --fcc-label-font-size: 1.5rem; /* font size of label */
  --fcc-label-color: #888; /* color of label */
  --fcc-background: #222; /* background of digit card */
  --fcc-divider-color: #111; /* color of divider */
  --fcc-divider-height: 1px; /* height of divider */
  --fcc-separator-size: 6px; /* size of colon */
  --fcc-separator-color: red; /* color of colon */
}

@media (min-width: 992px) {
  .main-card {
    width: 40rem;
  }

  .flip-clock {
    --fcc-spacing: 16px;
    --fcc-digit-font-size: 3rem;
    --fcc-digit-block-height: 75px;
  }

  .secondary-card {
    left: -115px;
  }
}

/* Default size for larger screens */
@media (min-width: 1200px) {
  #countdown-timer {
    transform: scale(1.2);
    /* Slightly larger for extra-large screens */
  }
}

/* Normal size for medium to large screens */
@media (min-width: 768px) and (max-width: 1199px) {
  #countdown-timer {
    transform: scale(1);
    /* Default size */
  }
}

/* Smaller size for small screens */
@media (max-width: 767px) {
  #countdown-timer {
    transform: scale(0.8);
    /* Reduce size slightly */
  }

  .main-card {
    width: 26rem;
  }

  .flip-clock {
    --fcc-spacing: 4px;
  }
}

@media (max-width: 576px) {
  .card-container {
    perspective: none;
    justify-content: start;
    padding: 1.5rem;
  }
  
  .main-card {
    transform: none;
  }
  
  .secondary-card {
    transform: none;
    left: 0;
    margin-top: 50px;
  }
}

@media (max-width: 450px) {
  .main-card, .secondary-card {
    width: 20rem;
  }

  .flip-clock {
    --fcc-spacing: 1px;
    --fcc-digit-font-size: 1.5rem;
    --fcc-digit-block-height: 40px;
    --fcc-digit-block-width: 30px;
    --fcc-label-font-size: 1rem;
    --fcc-spacing: 4px;
  }
}








/* General Page Styling */
/* body {
  background-color: #1b1b1b;
  font-family: Arial, sans-serif;
  color: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
}

#container {
  width: 80%;
  max-width: 1200px;
  background: #2a2a2a;
  border-radius: 12px;
  padding: 40px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}

h1 {
  font-size: 2.5em;
  color: rgb(255, 140, 0);
  text-align: center;
  margin-bottom: 10px;
}

h2 {
  font-size: 1.5em;
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

p {
  font-size: 1em;
  color: #ccc;
  text-align: center;
  line-height: 1.6;
}

#countdown-timer {
  background-color: #333;
  padding: 20px;
  border-radius: 12px;
  color: #fff;
  font-size: 1.2em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.fcc__digit_block {
  font-size: 2.5em;
  font-weight: bold;
  color: rgb(255, 140, 0);
}

.fcc__label {
  font-size: 0.9em;
  color: #bbb;
}

#video-section {
  margin: 20px 0;
  text-align: center;
}

#video-section video {
  width: 100%;
  max-width: 800px;
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

#signup-form {
  background-color: #333;
  padding: 20px;
  border-radius: 12px;
  max-width: 400px;
  margin: 20px auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

#signup-form h3 {
  font-size: 1.3em;
  color: rgb(255, 140, 0);
  text-align: center;
  margin-bottom: 15px;
}

#signup-form label {
  color: #bbb;
  font-size: 0.9em;
  margin-bottom: 5px;
  display: block;
}

#signup-form input[type="text"],
#signup-form input[type="email"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: none;
  background-color: #444;
  color: #fff;
  font-size: 1em;
}

#signup-form button {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  color: #fff;
  background-color: rgb(255, 140, 0);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

#signup-form button:hover {
  background-color: #e68a00;
}

a {
  color: rgb(255, 140, 0);
  font-weight: bold;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 5px;
  transition: color 0.3s, background-color 0.3s;
}

a:hover {
  color: #fff;
  background-color: rgb(255, 140, 0);
}

.text-links a {
  margin-right: 15px;
} */