body {
  background-color: #222 !important;
  color: #888;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-image: url('../public//mainBG.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; */
}

.btn:hover,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn.focus:active,
.btn:active:focus,
.btn:focus,
.btn:hover {
  background-image: none  !important;
  box-shadow: none !important;
}

.btn-outline-primary {
  border-color: rgb(255,140,0) !important;
  color: rgb(255,140,0) !important;
}

.btn-outline-primary:hover {
  background-color: rgb(255,140,0);
  color: white !important;
}

.border-primary-orange {
  border-color: rgb(255, 140, 0) !important;
}

.text-bg-darker {
  color: #888;
  background-color: #222;
}

.text-bg-darkest {
  color: #888;
  background-color: #111;
}

.bg-primary-orange {
  background-color: rgb(255, 140, 0);
}

.text-primary-orange {
  color: rgb(255, 140, 0);
}

.bg-darker {
  background-color: #222 !important;
}

.bg-darkest {
  background-color: #111 !important;
}

.flex-basis-0 {
  flex-basis: 0;
}

.flex-basis-45 {
  flex-basis: 45%;
}

.flex-basis-33 {
  flex-basis: 33.33%;
}

.fs-smaller {
  font-size: smaller;
}